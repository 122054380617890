import {API_ROUTE_DAYS, API_ROUTE_PRICES} from 'common/constants';
import {getAllPages} from 'common/getAllPages';
import {getPageData} from 'common/getPageData';
import {getQuery} from 'common/getQuery';
import {getSeoHost} from 'common/getSeoHost';
import {needDays} from 'common/needDays';
import {needPrices} from 'common/needPrices';
import {DaysApiResponse, PageProps, PricesApiResponse} from 'common/types';
import {useSWRStaticProps} from 'common/uswSWR';
import Header from 'components/header';
import Page from 'components/page';
import SanityBlockContent, {SanityWrapper} from 'components/sanityBlockContent';
import SeoHeader from 'components/seoHeader';
import {GetStaticProps, NextPage} from 'next';
import dynamic from 'next/dynamic';

const Error = dynamic(() => import('components/error'));

const SlugPage: NextPage<PageProps> = ({page, host, slug, days, prices}) => {
  if (!page) {
    return (
      <Page>
        <Error text='Die Seite konnte nicht gefunden werden.' />
        <SeoHeader title='Fehler' description='Die Seite konnte nicht gefunden werden.' host={host} slug={slug} />
      </Page>
    );
  }
  return (
    <Page>
      <SeoHeader title={page.seoTitle} description={page.seoDescription} image={page.image} host={host} slug={slug} />
      <Header
        title={page.title}
        title2={page.title2}
        title3={page.title3}
        image={page.image}
        cta={page.cta}
        ctaLink={page.ctaLink}
      />
      {page.content && (
        <SanityWrapper>
          <SanityBlockContent blocks={page.content} days={days || undefined} prices={prices || undefined} />
        </SanityWrapper>
      )}
    </Page>
  );
};

export const getStaticProps: GetStaticProps<PageProps> = async context => {
  const host = getSeoHost();
  const slug = getQuery('slug', 'index', context);
  let page = await getPageData(slug);
  let days: DaysApiResponse | null = null;
  let prices: PricesApiResponse | null = null;
  if (needDays(page)) {
    days = await useSWRStaticProps<DaysApiResponse>(API_ROUTE_DAYS);
  }
  if (needPrices(page)) {
    prices = await useSWRStaticProps<PricesApiResponse>(API_ROUTE_PRICES);
  }
  return {
    props: {
      slug,
      page,
      days,
      prices,
      host,
    },
    revalidate: 10,
    notFound: !page ? true : undefined,
  };
};

export const getStaticPaths = async () => {
  const pages = await getAllPages();
  const paths = pages.map(page => ({
    params: {
      slug: page.slug.current
        .replace('index', '')
        .replace('/', '')
        .split('/'),
    },
  }));
  return {
    paths,
    fallback: true,
  };
};

export default SlugPage;
